import imgT1 from "../assets/image/jpg/photo/regular/portfolio-1.jpg";
import imgT2 from "../assets/image/jpg/photo/regular/portfolio-2.jpg";
import imgT3 from "../assets/image/jpg/photo/regular/portfolio-3.jpg";
import imgT4 from "../assets/image/jpg/photo/regular/portfolio-4.jpg";
import imgT5 from "../assets/image/jpg/photo/regular/portfolio-5.jpg";
import imgT6 from "../assets/image/jpg/photo/regular/portfolio-6.jpg";
import imgT7 from "../assets/image/jpg/photo/regular/portfolio-7.jpg";
import imgT8 from "../assets/image/jpg/photo/regular/portfolio-8.jpg";
import imgT9 from "../assets/image/jpg/photo/regular/portfolio-9.jpg";
import imgT10 from "../assets/image/jpg/photo/regular/portfolio-10.jpg";
import imgT11 from "../assets/image/jpg/photo/regular/portfolio-11.jpg";
import imgT12 from "../assets/image/jpg/photo/regular/portfolio-12.jpg";
import imgT13 from "../assets/image/jpg/photo/regular/portfolio-13.jpg";
import imgT14 from "../assets/image/jpg/photo/regular/portfolio-14.jpg";
import imgT15 from "../assets/image/jpg/photo/regular/portfolio-15.jpg";
import imgT16 from "../assets/image/jpg/photo/regular/portfolio-16.jpg";

export const works1 = [
  {
    brand: `Exact Online / SRXP`,
    slug: `srxp`,
    title: `Expense management system`,
    categories: ["web"],
    thumbnail: imgT1,
    link : "/portfolio-details"
},
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`photography`, `branding`],
//     thumbnail: imgT2,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Shopper`,
//     slug: `shopper`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`, `branding`],
//     thumbnail: imgT3,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`],
//     thumbnail: imgT4,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `photography`, `ux-design`],
//     thumbnail: imgT5,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Ice Cream`,
//     slug: `ice-cream`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `marketing`],
//     thumbnail: imgT6,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Camera`,
//     slug: `Camera`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `photography`],
//     thumbnail: imgT7,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Smart Watch`,
//     slug: `smart-watch`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `ux-design`],
//     thumbnail: imgT8,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Smart Film`,
//     slug: `smart-film`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`, `photography`],
//     thumbnail: imgT9,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Coffee Cream`,
//     slug: `coffee-cream`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`],
//     thumbnail: imgT10,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Noodles`,
//     slug: `noodles`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `photography`],
//     thumbnail: imgT11,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Laptop Desk`,
//     slug: `laptop-desk`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`, `photography`, `branding`],
//     thumbnail: imgT12,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`],
//     thumbnail: imgT13,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Caffee Roast`,
//     slug: `coffee-roast`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`],
//     thumbnail: imgT14,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`],
//     thumbnail: imgT15,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `photography`, `ux-design`],
//     thumbnail: imgT16,
//   link : "/portfolio-details"
// },
];
