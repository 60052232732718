import srxp from '../assets/image/srxp.webp';
import novacair from '../assets/image/novacair.png';
import tjapp from '../assets/image/tjapp.jpg';
import grofalex from '../assets/image/grofalex.jpg';
import sighthub from '../assets/image/sighthub.jpg';
import lento from '../assets/image/lento.webp';

export const myWork = [
  {
    brand: `Exact Online / SRXP`,
    slug: `srxp`,
    title: `Expense management system`,
    categories: ["web", "fintech"],
    thumbnail: srxp,
    link : "/srxp"
  },
  {
    brand: `NovaCair`,
    slug: `novacair`,
    title: `Preoperative screening software`,
    categories: [`healthcare`],
    thumbnail: novacair,
    link : "/novacair"
},
  {
    brand: `Grofalex`,
    slug: `grofalex`,
    title: `Grofalex`,
    categories: [`ecology`],
    thumbnail: grofalex,
    link : "/grofalex"
  },
  {
    brand: `Tjapp`,
    slug: `tjapp`,
    title: `React Native - Recipe App`,
    categories: [`life-style`],
    thumbnail: tjapp,
    link : "/tjapp"
  },
  {
    brand: `SightHub`,
    slug: `sighthub`,
    title: `Video analytics for shops`,
    categories: [`ai`, 'video'],
    thumbnail: sighthub,
    link : "/sighthub"
  },
  {
    brand: `Lento`,
    slug: `lento`,
    title: `Online clothing store for conscious men`,
    categories: [`fashion-webshop`],
    thumbnail: lento,
    link : "/lento"
  },
//   {
//     brand: `Shopper`,
//     slug: `shopper`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`, `branding`],
//     thumbnail: imgT3,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`],
//     thumbnail: imgT4,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Travel App`,
//     slug: `travel-app`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `photography`, `ux-design`],
//     thumbnail: imgT5,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Ice Cream`,
//     slug: `ice-cream`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `marketing`],
//     thumbnail: imgT6,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Camera`,
//     slug: `Camera`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `photography`],
//     thumbnail: imgT7,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Smart Watch`,
//     slug: `smart-watch`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`branding`, `ux-design`],
//     thumbnail: imgT8,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Smart Film`,
//     slug: `smart-film`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`, `photography`],
//     thumbnail: imgT9,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Coffee Cream`,
//     slug: `coffee-cream`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `ux-design`],
//     thumbnail: imgT10,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Noodles`,
//     slug: `noodles`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`marketing`, `photography`],
//     thumbnail: imgT11,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Laptop Desk`,
//     slug: `laptop-desk`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`, `photography`, `branding`],
//     thumbnail: imgT12,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Candy Store`,
//     slug: `candy-store`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`],
//     thumbnail: imgT13,
//   link : "/portfolio-details"
// },
//   {
//     brand: `Caffee Roast`,
//     slug: `coffee-roast`,
//     title: `Adaptable but Identifiable Product for Kandinsky.`,
//     categories: [`ux-design`],
//     thumbnail: imgT14,
//   link : "/portfolio-details"
// },
];
